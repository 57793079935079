import React from 'react'

const PhotoGallery = () => {
  return (
    <div>
     photo Gallery
    </div>
  )
}

export default PhotoGallery
