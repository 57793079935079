import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import './Home.css'; // Custom styles
import image1 from '../images/1.jpeg'; // Replace with your actual image paths
import image2 from '../images/2.jpeg';
import image3 from '../images/logo2.jpg';
import image4 from '../images/logo3.png';

const Home = () => {
    return (
        <Container fluid className="carousel-container mt-4">
           <Carousel interval={3000} pause="hover" className="carousel-full-width"> {/* Add a custom class */}
          <Carousel.Item>
            <img className="d-block w-100" src={image1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={image2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={image3} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={image4} alt="Fourth slide" />
          </Carousel.Item>
        </Carousel>

            {/* About Section */}
            <section className="about-section mt-5">
                <h2 className="about-heading">About Sant. Gadge Maharaj Shikshan Sanskrutik Krida Mandal</h2>
                <p className="about-description">
                    Sant Gadge Maharaj Shikshan Sanskrutik Krida Mandal is an institution committed to fostering education,
                    culture, and sports in our society. Inspired by the great social reformer Sant Gadge Maharaj, the organization
                    aims to promote holistic development and instill values that create responsible citizens. With various educational 
                    and cultural initiatives, the institution empowers individuals to contribute positively to society.
                </p>
            </section>

            {/* Mission Statement Section */}
            <section className="mission-section mt-5">
                <h2 className="mission-heading">Our Mission</h2>
                <p className="mission-description">
                    Our mission is to empower individuals through education, promote cultural understanding, and encourage participation in sports.
                    We believe in holistic development, where students not only excel academically but also grow as leaders in their communities.
                </p>
            </section>

            {/* Activities Section */}
            <section className="activities-section mt-5">
                <h2 className="activities-heading">Our Activities</h2>
                <ul className="activities-list">
                    <li>Educational Programs: Workshops, seminars, and academic events to support student learning.</li>
                    <li>Cultural Initiatives: Organizing festivals, performances, and cultural exchange programs.</li>
                    <li>Sports Development: Promoting fitness through various sports activities and competitions.</li>
                    <li>Community Outreach: Social service programs aimed at improving the well-being of society.</li>
                </ul>
            </section>

            {/* Contact Information Section */}
            <section className="contact-section mt-5">
                <h2 className="contact-heading">Contact Us</h2>
                <p className="contact-info">
                    Address: 123 Sant Gadge Maharaj Road, City, State, ZIP Code<br />
                    Phone: (123) 456-7890<br />
                    Email: info@gadgemaharaj.org
                </p>
            </section>
        </Container>
    );
};

export default Home;
