import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Button, Container, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { NavLink, Link } from 'react-router-dom';
import { FaCalendarAlt, FaUserCircle, FaBars, FaTimes } from 'react-icons/fa'; // Import necessary icons
import './Header.css'; // Custom styles
import Banner from '../images/banner.jpg'; // Adjust the path to your banner image

const Header = () => {
    const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer visibility

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem component={NavLink} to="/" button>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem component={NavLink} to="/about" button>
                    <ListItemText primary="About" />
                </ListItem>
                <ListItem component={NavLink} to="/contact" button>
                    <ListItemText primary="Contact" />
                </ListItem>
                <ListItem component={NavLink} to="/photo-gallery" button>
                    <ListItemText primary="Photo Gallery" />
                </ListItem>
                <ListItem component={NavLink} to="/video-gallery" button>
                    <ListItemText primary="Video Gallery" />
                </ListItem>
                <ListItem component={NavLink} to="/latest-news" button>
                    <ListItemText primary="Latest News" />
                </ListItem>
            </List>
        </Box>
    );

    // Handle Donate button click
    const handleDonate = () => {
        // Redirect to PayPal payment link or integrate with PayPal API
        window.open('https://www.paypal.com/donate', '_blank'); // Example link, replace with your PayPal link
    };

    return (
        <>
            {/* Full-width Banner Image */}
            <Box
                component="img"
                src={Banner}
                alt="Banner"
                sx={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                }}
            />

            {/* AppBar for Navbar */}
            <AppBar position="static" sx={{ boxShadow: 'none' }} style={{ backgroundColor: '#221101c6' }}>
                <Container>
                    <Toolbar>
                        {/* Hamburger menu for mobile view */}
                        <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)} sx={{ display: { xs: 'block', md: 'none' } }}>
                            <FaBars />
                        </IconButton>

                        {/* Logo or Title for larger screens */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <Button component={Link} to="/" color="inherit" className="nav-button">Home</Button>
                            <Button component={Link} to="/about" color="inherit" className="nav-button">About</Button>
                            <Button component={Link} to="/contact" color="inherit" className="nav-button">Contact</Button>
                            <Button component={Link} to="/photo-gallery" color="inherit" className="nav-button">Photo Gallery</Button>
                            <Button component={Link} to="/video-gallery" color="inherit" className="nav-button">Video Gallery</Button>
                            <Button component={Link} to="/latest-news" color="inherit" className="nav-button">Latest News</Button>
                        </Box>

                        {/* Donation Button */}
                        <Button 
                            variant="contained" 
                            color="primary" 
                            sx={{ marginLeft: 'auto', bgcolor: '#90acd8', '&:hover': { bgcolor: '#62cf5f' } }} 
                            onClick={handleDonate} // Add onClick event
                        >
                            Donate
                        </Button>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* Marquee Section */}
            <marquee behavior="scroll" direction="left" className="marquee-text">
                Welcome to our website!
            </marquee>

            {/* Drawer for Mobile Navigation */}
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    style={{ backgroundColor: '#5bd055', color: 'white' }}
                >
                    <IconButton onClick={toggleDrawer(false)} sx={{ marginLeft: 'auto' }}>
                        <FaTimes />
                    </IconButton>
                    {list()}
                </Box>
            </Drawer>
        </>
    );
};

export default Header;
