import React from 'react';
import './Footer.css'; // Optional: for further custom styles if needed

const Footer = () => {
  return (
    <footer className="bg-dark text-white mt-5">
      <div className="container py-4">
        <div className="row text-center text-md-left">
          {/* About Section */}
          <div className="col-md-4 mb-3">
            <h5>About Sant Gadge Maharaj</h5>
            <p>
              Sant Gadge Maharaj Shikshan Sanskrutik Krida Mandal is committed to uplifting society through education, cultural activities, and sports. We strive to carry forward the teachings of Sant Gadge Baba by focusing on cleanliness, education, and service.
            </p>
          </div>

          {/* Contact Information */}
          <div className="col-md-4 mb-3">
            <h5>Contact Us</h5>
            <ul className="list-unstyled">
              <li><strong>Address:</strong> Shendgaon, Amravati District, Maharashtra, India</li>
              <li><strong>Email:</strong> <a href="mailto:info@gadgemaharaj.org" className="text-white">info@gadgemaharaj.org</a></li>
              <li><strong>Phone:</strong> <a href="tel:+911234567890" className="text-white">+91-123-456-7890</a></li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div className="col-md-4 mb-3">
            <h5>Follow Us</h5>
            <ul className="list-unstyled d-flex justify-content-center justify-content-md-start">
              <li className="mr-3">
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-white">
                  <i className="fab fa-facebook-f"></i> Facebook
                </a>
              </li>
              <li className="mr-3">
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="text-white">
                  <i className="fab fa-twitter"></i> Twitter
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-white">
                  <i className="fab fa-instagram"></i> Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      {/* Bottom copyright section */}
      <div className="bg-primary text-center py-2">
        <p className="mb-0">&copy; {new Date().getFullYear()} Sant Gadge Maharaj Shikshan Sanskrutik Krida Mandal. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
