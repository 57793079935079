import React from 'react'

const VideoGallery = () => {
  return (
    <div>
      video
    </div>
  )
}

export default VideoGallery
